// Stretch content to page width
$content-width: 80%;

@import "minima";


// Dropdown Button
.dropbtn {
  font: inherit;
  background-color: $background-color;
  color: $text-color;
  border: none;
}

// The container <div> - needed to position the dropdown content
.dropdown {
  position: relative;
  display: inline-block;
}

// Dropdown Content (Hidden by Default)
.dropdown-content {
  display: none;
  position: absolute;
  background-color: lighten($grey-color-light, 6%);
  box-shadow: 0px 8px 16px 0px $grey-color;
  z-index: 1;
}

// Links inside the dropdown
.dropdown-content a {
  color: $text-color;
  padding-top: 0;
  padding-right: 10px;
  padding-bottom: 0;
  padding-left: 10px;
  text-decoration: none;
  display: block;
}

// Change color of dropdown links on hover
.dropdown-content a:hover {
  // Color adapted to table color
  background-color: lighten($grey-color-light, 3%);
}

// Show the dropdown menu on hover
.dropdown:hover .dropdown-content {
  display: block;
}

// Change the background color of the dropdown button when the
// dropdown content is shown
.dropdown:hover .dropbtn {
  // Color adapted to table color
  background-color: lighten($grey-color-light, 6%);
}
